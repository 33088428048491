
  export default {
    name: `MyCheckTimeline`,
    props: {
      timeline: {
        type: Object,
        default: undefined
      },
      status: {
        type: Object,
        default: undefined
      },
      deletionDate: {
        type: String,
        default: undefined
      }
    },
    computed: {
      isCancelled() {
        return this.status?.code === `cancelled`
      }
    },
    methods: {
      getFormattedDate(date, wHM=false) {
        const format = wHM ? `DD.MM.YYYY HH:mm` : `DD.MM.YYYY`
        return !!date ? this.$moment.utc(date, `DD-MM-YYYY hh:mm`).local().format(format) : ``
      }
    }
  }
